import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  isAfter,
  isBefore,
  isSameDay,
  startOfMonth,
  startOfWeek,
  startOfYear
} from 'date-fns';
import {AbstractControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import get from 'lodash/get';
import {Periodicidad, PeriodicidadEnum, PeriodicidadVisual} from "@shared/enum/enums";

export function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export const FormUtils = {
  validators: {
    atLeastOneRequired: (field1: string, field2: string): ValidatorFn => (group: FormGroup) => {
      if (group) {
        if (group.controls[field1].value || group.controls[field2].value) {
          return null;
        }
      }
      return {'atleast-one-required': [field1, field2]};
    },
    conditionTrue: (condition: boolean): ValidatorFn => (control: AbstractControl) => {
      if (condition) {
        return null;
      }
      return {'condition-not-true': true};
    },
    requireLengthOf: (length: number): ValidatorFn => (control: AbstractControl) => {
      if (control.value && control.value.length && control.value.length === length) {
        return null;
      }
      return {'required-lenght': length};
    },
    indexesNotNull: (...indexes: number[]): ValidatorFn => (control: AbstractControl) => {
      if (control.value && control.value.length && control.value.filter((v, i) => indexes.includes(i) && v === undefined).length === 0) {
        return null;
      }
      return {'indexes-null': true};
    },
    allValuesNotNull: (control: AbstractControl) => {
      if (control.value && control.value.length && control.value.filter((v) => v === undefined).length === 0) {
        return null;
      }
      return {'all-values-not-null': true};
    },
    onlyNumbers: (control: AbstractControl) => {
      const value: string = control.value || '';
      const valid = value.match(/^[0-9]*$/);
      return valid ? null : {'only-numbers': true};
    }
  },
  functions: {}
};

export const FormValidatorGroups = {
  fechas: [Validators.required, FormUtils.validators.requireLengthOf(2), FormUtils.validators.indexesNotNull(0)],
  fechas_mandatory: [Validators.required, FormUtils.validators.requireLengthOf(2), FormUtils.validators.allValuesNotNull]
};

export const DateUtils = {
  withoutTimezone: (date: Date) => {
    const d = new Date(date);
    const timeZoneDifference = (d.getTimezoneOffset() / 60) * -1;
    d.setTime(d.getTime() + timeZoneDifference * 60 * 60 * 1000);
    return d;
  },
  isBefore: (thisOne: Date, thatOne: Date) => {
    return isBefore(thisOne, thatOne);
  },
  isAfter: (thisOne: Date, thatOne: Date) => {
    return isAfter(thisOne, thatOne);
  },
  isBeforeOrSame: (thisOne: Date, thatOne: Date) => {
    return isBefore(thisOne, thatOne) || isSameDay(thisOne, thatOne);
  },
  isAfterOrSame: (thisOne: Date, thatOne: Date) => {
    return isAfter(thisOne, thatOne) || isSameDay(thisOne, thatOne);
  },
  datesBetween: (startDate: Date, stopDate: Date): Date[] => {
    var dateArray = [];
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate = addDays(currentDate, 1);
    }
    return dateArray;
  },
  monthsBetween: (startDate: Date, stopDate: Date) => {
    var dateArray = [];
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate = startOfMonth(addMonths(currentDate, 1));
    }
    return dateArray;
  },
  weeksBetween: (startDate: Date, stopDate: Date) => {
    var dateArray = [];
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate = startOfWeek(addWeeks(currentDate, 1));
    }
    return dateArray;
  },
  yearsBetween: (startDate: Date, stopDate: Date) => {
    var dateArray = [];
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate = startOfYear(addYears(currentDate, 1));
    }
    return dateArray;
  },
  generateArrayOfYears: (numYears = 10) => {
    var max = new Date().getFullYear()
    var min = max - numYears
    var years = []

    for (var i = max; i >= min; i--) {
      years.push(i)
    }
    return years
  }
};

export const DataUtils = {
  getByPath: (value, path) => get(value, path)
};

export const CronUtils = {
  getPeriocidadVisual: (periocidad: Periodicidad, cronExpression: string): PeriodicidadVisual => {
    const cron = CronUtils.loadExpression(cronExpression);
    switch (periocidad) {
      case PeriodicidadEnum.DIARIO:
        return "Cada día"
      case PeriodicidadEnum.SEMANAL:
        return "Cada semana"
      case PeriodicidadEnum.ANUAL:
        if (cron.year.indexOf('/') < 0) return "Cada año";
        const repeatYear = +cron.year.split('/')[1];
        switch (repeatYear) {
          case 2:
            return "Cada 2 años";
          case 3:
            return "Cada 3 años";
          default:
            return "Cada año";
        }
      case PeriodicidadEnum.MENSUAL:
      case PeriodicidadEnum.BIMESTRAL:
      case PeriodicidadEnum.TRIMESTRAL:
      case PeriodicidadEnum.CUATRIMESTRAL:
      case PeriodicidadEnum.SEMESTRAL:
        if (cron.month.indexOf('/') < 0) return "Cada mes";
        const repeatMonth = +cron.month.split('/')[1];
        switch (repeatMonth) {
          case 2:
            return "Cada 2 meses";
          case 3:
            return "Cada 3 meses";
          case 4:
            return "Cada 4 meses";
          case 6:
            return "Cada 6 meses";
          default:
            return "Cada mes";
        }
      default:
        return PeriodicidadEnum.PUNTUAL;
    }
  },
  loadExpression: (expresionCron: string) => {
    let elementosCron: string[];
    let cronParts: CronParts = {};

    if (expresionCron) {
      elementosCron = expresionCron.split(' ').map(el => el.trim());
      if (elementosCron.length === 7) {
        cronParts = {
          minute: elementosCron[1],
          hour: elementosCron[2],
          day: elementosCron[3],
          month: elementosCron[4],
          week: elementosCron[5].split(','),
          year: elementosCron[6]
        };
      }
    }
    return cronParts;
  },

  generarExpresionCron(generadorCron: {periodicidad: PeriodicidadEnum, cronParts: any, parteVariable: string[], fechaPuntual?: Date, fechaAplicacion?: Date}): {periodicidad: PeriodicidadEnum, cron: string} {
    let cron = '0 0 8 ? * * *';
    switch (generadorCron.periodicidad) {
      case PeriodicidadEnum.DIARIO:
        generadorCron.cronParts.day = CronUtils.generarParteVariableCron(generadorCron.parteVariable[0], generadorCron.fechaAplicacion, PeriodicidadEnum.DIARIO);
        cron = `0 0 8 ${generadorCron.cronParts.day} * ? *`;
        break;
      case PeriodicidadEnum.SEMANAL:
        const undfindex = generadorCron.cronParts.week.indexOf('?');

        if (undfindex >= 0 && generadorCron.cronParts.week.length > 1) {
          generadorCron.cronParts.week.splice(undfindex, 1);
        }

        cron = `0 0 8 ? * ${generadorCron.cronParts.week.join(',').toString()} *`;
        break;
      case PeriodicidadEnum.MENSUAL:
        generadorCron.cronParts.month = CronUtils.generarParteVariableCron(generadorCron.parteVariable[1], generadorCron.fechaAplicacion, PeriodicidadEnum.MENSUAL);
        cron = `0 0 8 ${generadorCron.cronParts.day} ${generadorCron.cronParts.month} ? *`;
        switch (generadorCron.parteVariable[1]) {
          case '2':
            generadorCron.periodicidad = PeriodicidadEnum.BIMESTRAL;
            break;
          case '3':
            generadorCron.periodicidad = PeriodicidadEnum.TRIMESTRAL;
            break;
          case '4':
            generadorCron.periodicidad = PeriodicidadEnum.CUATRIMESTRAL;
            break;
          case '6':
            generadorCron.periodicidad = PeriodicidadEnum.SEMESTRAL;
            break;
          case '*':
            generadorCron.periodicidad = PeriodicidadEnum.MENSUAL;
            cron = `0 0 8 ${generadorCron.cronParts.day} * ? *`;
            break;
        }
        break;
      case PeriodicidadEnum.ANUAL:
        generadorCron.cronParts.year = CronUtils.generarParteVariableCron(generadorCron.parteVariable[2], generadorCron.fechaAplicacion, PeriodicidadEnum.ANUAL);
        cron = `0 0 8 ${generadorCron.cronParts.day} ${generadorCron.cronParts.month} ? ${generadorCron.cronParts.year}`;
        break;
      case PeriodicidadEnum.PUNTUAL:
        if (generadorCron.fechaPuntual) {
          cron = `0 0 8 ${generadorCron.fechaPuntual.getDate()} ${(generadorCron.fechaPuntual.getMonth() + 1)} ? ${generadorCron.fechaPuntual.getFullYear()}`;
        }
        break;
    }
    return {
      periodicidad: generadorCron.periodicidad,
      cron: cron
    }
  },

  generarParteVariableCron(parteVariable: string | null, fechaAplicacion: Date, periodicidad: PeriodicidadEnum) {
    if (parteVariable && parteVariable != '*'){
      let parteResultado = '';
      if (periodicidad == PeriodicidadEnum.DIARIO) {
        parteResultado = fechaAplicacion && parteVariable != null ? (fechaAplicacion.getDate()).toString() : '1';
      }
      if (periodicidad == PeriodicidadEnum.MENSUAL){
        parteResultado = fechaAplicacion && parteVariable != null ? (fechaAplicacion.getMonth() + 1).toString() : '1';
      }
      if (periodicidad == PeriodicidadEnum.ANUAL) {
        parteResultado = fechaAplicacion && parteVariable != null ? (fechaAplicacion.getFullYear()).toString() : '2020';
      }
      return parteResultado.concat(`/${parteVariable}`);
    } else {
      return '*';
    }
  }
}

export interface CronParts {
  minute?: string;
  hour?: string;
  day?: string;
  month?: string;
  week?: string[];
  year?: string;
}

export interface Frecuencia {
  periodicidad?: PeriodicidadEnum,
  repeticiones?: number[],
  mes?: string | number,
  dia?: string | number
  fecha?: Date | string
}

export function isPeriodicidadMensual(periodicidad: PeriodicidadEnum) {
  return [
    PeriodicidadEnum.MENSUAL,
    PeriodicidadEnum.BIMESTRAL,
    PeriodicidadEnum.TRIMESTRAL,
    PeriodicidadEnum.CUATRIMESTRAL,
    PeriodicidadEnum.SEMESTRAL
  ].includes(periodicidad);
}
