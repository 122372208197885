import {HttpClient, HttpResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {PaginatedResponse, SearchRequest} from '@azigrene/searchrequest';
import {Identificable} from '@shared/interfaces/model-interfaces';
import {
  IBackendAccionMasivaService,
  IBackendService,
  ICRUDService,
  IExportService,
  IImportService,
  IPaginatedService
} from '@shared/interfaces/service-interfaces';

import {Observable} from 'rxjs';
import {ResultadoValidacionModel} from "@shared/models/resultado-validacion.model";

@Injectable()
export abstract class AbstractAPIService {
  http = inject(HttpClient);

  getHttp(): HttpClient {
    return this.http;
  }

  abstract buildUrlBase(args?: unknown): string;
}

export interface INoPaginatedService<ItemModel extends Identificable, BaseUrlArgsType> extends AbstractAPIService {
  getAll(args?: BaseUrlArgsType): Observable<ItemModel[]>;
}

export interface INoPaginatedSearchableService<ItemModel extends Identificable, BaseUrlArgsType> extends INoPaginatedService<ItemModel, BaseUrlArgsType> {
  search(sr: SearchRequest, args?: BaseUrlArgsType): Observable<ItemModel[]>;
}

export abstract class AbstractPaginatedService<ItemModel extends Identificable, BaseUrlArgsType> extends AbstractAPIService implements IPaginatedService<ItemModel, BaseUrlArgsType> {
  getAllPaginated(searchRequest: SearchRequest, args?: BaseUrlArgsType): Observable<PaginatedResponse<ItemModel>> {
    return this.getHttp().post<PaginatedResponse<ItemModel>>(this.buildUrlBase(args) + '/all', searchRequest);
  }
}

export abstract class AbstractCRUDService<ItemModel extends Identificable, ItemRequest extends Identificable, BaseUrlArgsType> extends AbstractAPIService implements ICRUDService<ItemModel, ItemRequest, BaseUrlArgsType> {
  get(id: number, args?: BaseUrlArgsType): Observable<ItemModel> {
    return this.getHttp().get<ItemModel>(this.buildUrlBase(args) + '/' + id + '/one');
  }

  create(value: ItemRequest, args?: BaseUrlArgsType): Observable<ItemModel> {
    return this.getHttp().post<ItemModel>(this.buildUrlBase(args) + '/create', value);
  }

  update(value: ItemRequest, args?: BaseUrlArgsType): Observable<ItemModel> {
    return this.getHttp().put<ItemModel>(this.buildUrlBase(args) + '/' + value.id + '/update', value);
  }

  delete(id: number, args?: BaseUrlArgsType): Observable<boolean> {
    return this.getHttp().delete<boolean>(this.buildUrlBase(args) + '/' + id + '/delete');
  }
}


export abstract class AbstractImportService<BaseUrlArgsType> extends AbstractAPIService implements IImportService<BaseUrlArgsType> {
  import(file: unknown, formato?: string, args?: BaseUrlArgsType): Observable<ResultadoValidacionModel> {
    if (formato) {
      return this.getHttp().post<ResultadoValidacionModel>(this.buildUrlBase(args) + '/import/' + formato, file);
    } else {
      return this.getHttp().post<ResultadoValidacionModel>(this.buildUrlBase(args) + '/import', file);
    }
  }
}
export abstract class AbstractExportService<BaseUrlArgsType> extends AbstractAPIService implements IExportService<BaseUrlArgsType> {
  export(searchRequest: SearchRequest, formato?: string, args?: BaseUrlArgsType): Observable<HttpResponse<Blob>> {
    if (formato) {
      return this.getHttp().post(this.buildUrlBase(args) + '/export/' + formato, searchRequest, {observe: 'response', responseType: 'blob'});
    } else {
      return this.getHttp().post(this.buildUrlBase(args) + '/export', searchRequest, {observe: 'response', responseType: 'blob'});
    }
  }
}

export abstract class AbstractAccionMasivaService<AccionMasivaContentModel, BaseUrlArgsType> extends AbstractAPIService {
  accionMasiva(searchRequest: SearchRequest, accion: string, data: AccionMasivaContentModel, args?: BaseUrlArgsType): Observable<number> {
    return this.getHttp().post<number>(`${this.buildUrlBase(args)}/accion-masiva`, {searchRequest, data, accion});
  }

  accionLista(ids: number[], accion: string, data: AccionMasivaContentModel, args?: BaseUrlArgsType): Observable<number> {
    return this.getHttp().post<number>(`${this.buildUrlBase(args)}/accion-lista`, {ids, data, accion});
  }
}

export abstract class AbstractBackendService<ItemModel extends Identificable, ItemRequest extends Identificable, BaseUrlArgsType> extends AbstractAPIService implements IBackendService<ItemModel, ItemRequest, BaseUrlArgsType> {
  getAllPaginated(searchRequest: SearchRequest, args?: BaseUrlArgsType): Observable<PaginatedResponse<ItemModel>> {
    return this.getHttp().post<PaginatedResponse<ItemModel>>(this.buildUrlBase(args) + '/all', searchRequest);
  }

  getAll( args?: BaseUrlArgsType): Observable<ItemModel[]> {
    return this.getHttp().get<ItemModel[]>(this.buildUrlBase(args) + '/all');
  }

  get(id: number, args?: BaseUrlArgsType): Observable<ItemModel> {
    return this.getHttp().get<ItemModel>(this.buildUrlBase(args) + '/' + id + '/one');
  }

  create(value: ItemRequest, args?: BaseUrlArgsType): Observable<ItemModel> {
    return this.getHttp().post<ItemModel>(this.buildUrlBase(args) + '/create', value);
  }

  update(value: ItemRequest, args?: BaseUrlArgsType): Observable<ItemModel> {
    return this.getHttp().put<ItemModel>(this.buildUrlBase(args) + '/' + value.id + '/update', value);
  }

  delete(id: number, args?: BaseUrlArgsType): Observable<boolean> {
    return this.getHttp().delete<boolean>(this.buildUrlBase(args) + '/' + id + '/delete');
  }

  import(file: unknown, formato?: string, args?: BaseUrlArgsType): Observable<ResultadoValidacionModel> {
    if (formato) {
      return this.getHttp().post<ResultadoValidacionModel>(this.buildUrlBase(args) + '/import/' + formato, file);
    } else {
      return this.getHttp().post<ResultadoValidacionModel>(this.buildUrlBase(args) + '/import', file);
    }
  }

  export(searchRequest: SearchRequest, formato?: string, args?: BaseUrlArgsType): Observable<HttpResponse<Blob>> {
    if (formato) {
      return this.getHttp().post(this.buildUrlBase(args) + '/export/' + formato, searchRequest, {observe: 'response', responseType: 'blob'});
    } else {
      return this.getHttp().post(this.buildUrlBase(args) + '/export', searchRequest, {observe: 'response', responseType: 'blob'});
    }
  }
}

export abstract class AbstractBackendAccionMasivaService<ItemModel extends Identificable, ItemRequest extends Identificable, BaseUrlArgsType, AccionMasivaModel> extends AbstractAPIService implements IBackendAccionMasivaService<ItemModel, ItemRequest, BaseUrlArgsType, AccionMasivaModel> {
  getAllPaginated(searchRequest: SearchRequest, args?: BaseUrlArgsType): Observable<PaginatedResponse<ItemModel>> {
    return this.getHttp().post<PaginatedResponse<ItemModel>>(this.buildUrlBase(args) + '/all', searchRequest);
  }
  get(id: number, args?: BaseUrlArgsType): Observable<ItemModel> {
    return this.getHttp().get<ItemModel>(this.buildUrlBase(args) + '/' + id + '/one');
  }

  create(value: ItemRequest, args?: BaseUrlArgsType): Observable<ItemModel> {
    return this.getHttp().post<ItemModel>(this.buildUrlBase(args) + '/create', value);
  }

  update(value: ItemRequest, args?: BaseUrlArgsType): Observable<ItemModel> {
    return this.getHttp().put<ItemModel>(this.buildUrlBase(args) + '/' + value.id + '/update', value);
  }

  delete(id: number, args?: BaseUrlArgsType): Observable<boolean> {
    return this.getHttp().delete<boolean>(this.buildUrlBase(args) + '/' + id + '/delete');
  }

  import(file: unknown, formato?: string, args?: BaseUrlArgsType): Observable<ResultadoValidacionModel> {
    if (formato) {
      return this.getHttp().post<ResultadoValidacionModel>(this.buildUrlBase(args) + '/import/' + formato, file);
    } else {
      return this.getHttp().post<ResultadoValidacionModel>(this.buildUrlBase(args) + '/import', file);
    }
  }

  export(searchRequest: SearchRequest, formato?: string, args?: BaseUrlArgsType): Observable<HttpResponse<Blob>> {
    if (formato) {
      return this.getHttp().post(this.buildUrlBase(args) + '/export/' + formato, searchRequest, {observe: 'response', responseType: 'blob'});
    } else {
      return this.getHttp().post(this.buildUrlBase(args) + '/export', searchRequest, {observe: 'response', responseType: 'blob'});
    }
  }

  accionMasiva(searchRequest: SearchRequest, accion: string, data: AccionMasivaModel, args?: BaseUrlArgsType): Observable<number> {
    return this.getHttp().post<number>(`${this.buildUrlBase(args)}/accion-masiva`, {searchRequest, data, accion});
  }

  accionLista(ids: number[], accion: string, data: AccionMasivaModel, args?: BaseUrlArgsType): Observable<number> {
    return this.getHttp().post<number>(`${this.buildUrlBase(args)}/accion-lista`, {ids, data, accion});
  }
}
