import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { AssigneeModel } from '@azigrene/components';
import { MiembroService } from '../../../../modules/frontoffice/services/miembro.service';
import { MiembrosArray, MiembroUsuario } from '@shared/models/home.model';
import { Miembro, MiembroTarea } from '@shared/models/auth.model';
import { TareaActions } from '../../../../modules/frontoffice/actions/TareaActions';
import { TareaService } from '../../../../modules/frontoffice/services/tarea.service';
import { map } from 'rxjs/operators';
import { AzOverlayComponent } from '@azigrene/components';

@Component({
  selector: 'app-assignees-selector-dropdown',
  template: `
    <az-assignees-circle-dropdown class="flex-1"
                  [dropdown]="dropdown"
                  [assigneeListObservable]="assigneeObs"
                  (onChange)="updateAssignees($event)"
                  [editable]="true" [value]="_assignees">
    </az-assignees-circle-dropdown>
  `,
  styles: []
})
export class AssigneesSelectorDropdownComponent implements OnInit, OnChanges {
  @Input() dropdown: AzOverlayComponent;
  @Input() contratoId: number;
  @Input() tareaId: number;
  @Input() assignees: MiembrosArray;
  @Output() onUpdateSuccess = new EventEmitter<MiembroUsuario[]>();

  assigneeObs: () => Observable<AssigneeModel[]>;
  _assignees: AssigneeModel[];

  constructor(private miembroService: MiembroService, private tareaService: TareaService) {
    this.assigneeObs = () => this.miembroService.all(this.contratoId).pipe(map((e: Miembro[]) => {
      return e.map(v => ({
        id: v.id,
        username: v.usuario.email
      }));
    }));
  }

  ngOnInit() {
  }

  updateAssignees(event: AssigneeModel[]) {
    const members: number[] = event.map(e => e.id);
    this.tareaService.accionLista( [this.tareaId], TareaActions.SET_ASSIGNEES, { assignees: members }).subscribe(n => {
      this.onUpdateSuccess.emit();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.assignees) {
      this._assignees = changes.assignees.currentValue ? changes.assignees.currentValue.jsonArray.map(v => ({
        id: v.miembroId,
        username: v.email
      })) : [];
    }
  }

}
