import {DataSourceDataStatus} from '@azigrene/searchrequest';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {GenericDataSourceModel} from 'src/app/shared/models/generic.model';
import {Contratante, Contrato, EstadoFiltro} from 'src/app/shared/models/home.model';
import * as HomeActions from './home.actions';
import {Injectable} from '@angular/core';
import {EstadoGeneralModel} from "../../modules/frontoffice/pages/general-status/estado";

interface HomeStateModel {
  accountSettings: {
    hourFormat: string;
    timezone: string;
    dateFormat: string;
    locale: string;
    currencyCode: string;
  };
  userConfig: {
    proyectosFavoritos: Contrato[];
  };
  tareasHoy: EstadoGeneralModel[];
  contratantes: GenericDataSourceModel<Contratante>;
  contratos: GenericDataSourceModel<Contrato>;
  contratoActivo: Contrato;
  estadoFiltro: EstadoFiltro;
}

@State<HomeStateModel>({
  name: 'home',
  defaults: {
    accountSettings: {
      hourFormat: 'HH:mm',
      timezone: 'Europe/Madrid',
      dateFormat: 'DD/MM/YYYY',
      locale: 'es',
      currencyCode: '€'
    },
    contratantes: {
      data: [],
      status: DataSourceDataStatus.empty,
      searchRequest: null
    },
    contratos: {
      data: [],
      status: DataSourceDataStatus.empty,
      searchRequest: null
    },
    userConfig: {
      proyectosFavoritos: []
    },
    contratoActivo: null,
    estadoFiltro: {
      searchRequest: null,
      clientesSeleccionados: null,
      estadosRevisionSeleccionados: null,
      periodicidadesSeleccionadas: null,
      revisable: null,
      tareasSeleccionadas: null,
      fechasSeleccionadas: null,
      responsableSeleccionado: null,
      asignadoSeleccionado: null,
      mesesSeleccionados: null,
      tareasRetrasoSeleccionadas: null,
      etiquetasSeleccionadas: null
    },
    tareasHoy: []
  }
})
@Injectable()
export class HomeState {

  @Selector()
  static accountSettings(state: HomeStateModel) {
    return state.accountSettings;
  }

  @Selector()
  static getContratoActivo(state: HomeStateModel) {
    return state.contratoActivo;
  }

  @Selector()
  static getListaContratante(state: HomeStateModel) {
    return state.contratantes.data;
  }

  @Selector()
  static getListaContrato(state: HomeStateModel) {
    return state.contratos.data;
  }

  @Selector()
  static proyectosFavoritos(state: HomeStateModel) {
    return state.userConfig.proyectosFavoritos;
  }

  @Selector()
  static isProyectoFavorito(state: HomeStateModel) {
    return (id: number) => {
      return state.userConfig ? state.userConfig.proyectosFavoritos.findIndex((s) => s.id === id) >= 0 : false;
    };
  }

  @Selector()
  static tareasHoy(state: HomeStateModel) {
    return state.tareasHoy;
  }

  @Selector()
  static estadoFiltro(state: HomeStateModel) {
    return state.estadoFiltro;
  }

  @Action(HomeActions.SetContratoActivo)
  SetContratoActivo(ctx: StateContext<HomeStateModel>, action: HomeActions.SetContratoActivo) {
    ctx.patchState({contratoActivo: action.payload});
  }

  @Action(HomeActions.SetProyectoFavorito)
  proyectoFav(ctx: StateContext<HomeStateModel>, action: HomeActions.SetProyectoFavorito) {
    const proyecto = action.payload;
    const userConfig = ctx.getState().userConfig;

    if (!userConfig) {
      return ctx.patchState({
        userConfig: {
          ...userConfig,
          proyectosFavoritos: [proyecto]
        }
      });
    }

    if (userConfig.proyectosFavoritos.findIndex((p) => p.id === proyecto.id) < 0) {
      return ctx.patchState({
        userConfig: {
          ...userConfig,
          proyectosFavoritos: [...userConfig.proyectosFavoritos, proyecto]
        }
      });
    }
  }

  @Action(HomeActions.UnsetProyectoFavorito)
  proyectoUnfav(ctx: StateContext<HomeStateModel>, action: HomeActions.UnsetProyectoFavorito) {
    const proyecto = action.payload;
    const userConfig = ctx.getState().userConfig;

    if (userConfig == null) {
      return ctx.patchState({
        userConfig: {
          proyectosFavoritos: []
        }
      });
    }

    const proyectos = [...userConfig.proyectosFavoritos];
    const index = proyectos.findIndex((p) => p.id === proyecto.id);

    if (index >= 0) {
      proyectos.splice(index, 1);

      return ctx.patchState({
        userConfig: {
          ...userConfig,
          proyectosFavoritos: proyectos
        }
      });
    }
  }

  @Action(HomeActions.RefreshProyectosFavoritos)
  refreshProyectos(ctx: StateContext<HomeStateModel>, action: HomeActions.RefreshProyectosFavoritos) {
    const proyectos = action.payload;
    const userConfig = ctx.getState().userConfig;

    if (!userConfig) {
      return;
    }

    let proyectosFav = userConfig.proyectosFavoritos || [];

    proyectosFav = proyectosFav.filter(p => proyectos.findIndex(v => v.id === p.id) >= 0);
    proyectosFav.forEach(p => {
      const rp = proyectos.find(v => v.id === p.id);

      if (rp) {
        p = rp;
      }
    });

    return ctx.patchState({
      userConfig: {
        ...userConfig,
        proyectosFavoritos: proyectosFav
      }
    });
  }

  @Action(HomeActions.PreloadAccountSettings)
  SetAccountSettings(ctx: StateContext<HomeStateModel>, action: HomeActions.PreloadAccountSettings) {
    const accountSettings = ctx.getState().accountSettings;

    if (!accountSettings) {
      ctx.patchState({
        accountSettings: {
          hourFormat: 'hh',
          timezone: 'Europe/Madrid',
          dateFormat: 'DD/MM/YYYY',
          locale: 'es',
          currencyCode: '€'
        }
      });
    }
  }

  @Action(HomeActions.SetEstadoFiltroAction)
  SetEstadoFiltroAction(ctx: StateContext<HomeStateModel>, action: HomeActions.SetEstadoFiltroAction) {
    ctx.patchState({
      estadoFiltro: action.payload
    });
  }

  @Action(HomeActions.ActualizarTareasHoy)
  actualizarTareasHoy(ctx: StateContext<HomeStateModel>, action: HomeActions.ActualizarTareasHoy) {
    ctx.patchState({
      tareasHoy: action.payload
    });
  }
}
