import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AsistenciaTecnica } from '@shared/models/home.model';
import { PaginatedResponse, SearchRequest } from '@azigrene/searchrequest';

@Injectable()
export class BackofficeAssistanceService {
  constructor(private http: HttpClient) {
  }

  all() {
    return this.http.get<AsistenciaTecnica[]>(`${environment.urls.api}/backoffice/assistance`);
  }

  allPaginated(searchRequest: SearchRequest) {
    return this.http.post<PaginatedResponse<AsistenciaTecnica>>(
      `${environment.urls.api}/backoffice/assistance`,
      searchRequest
    );
  }

  one(id: number) {
    return this.http.get<AsistenciaTecnica>(`${environment.urls.api}/backoffice/assistance/${id}`);
  }

  create(data: AsistenciaTecnica) {
    return this.http.post<AsistenciaTecnica>(
      `${environment.urls.api}/backoffice/assistance/create`,
      data
    );
  }

  update(id: number, data: AsistenciaTecnica) {
    return this.http.post<AsistenciaTecnica>(
      `${environment.urls.api}/backoffice/assistance/${id}/update`,
      data
    );
  }

  delete(id: number) {
    return this.http.delete<AsistenciaTecnica>(
      `${environment.urls.api}/backoffice/assistance/${id}/delete`
    );
  }
}
