import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { PaginatedResponse, SearchRequest } from '@azigrene/searchrequest';
import { PlantillaContrato } from '@shared/models/home.model';

@Injectable()
export class BackofficePlantillaContratoService {
  constructor(private http: HttpClient) {}

  all(tipoContratoId: number) {
    return this.http.get<PlantillaContrato[]>(`${environment.urls.api}/backoffice/template`);
  }
  allPaginated(searchRequest: SearchRequest) {
    return this.http.post<PaginatedResponse<PlantillaContrato>>(
      `${environment.urls.api}/backoffice/template`,
      searchRequest
    );
  }
  one(id: number) {
    return this.http.get<PlantillaContrato>(`${environment.urls.api}/backoffice/template/${id}`);
  }

  create(data: PlantillaContrato) {
    return this.http.post<PlantillaContrato>(
      `${environment.urls.api}/backoffice/template/create`,
      data
    );
  }

  update(id: number, data: PlantillaContrato) {
    return this.http.post<PlantillaContrato>(
      `${environment.urls.api}/backoffice/template/${id}/update`,
      data
    );
  }
  delete(id: number) {
    return this.http.delete<PlantillaContrato>(
      `${environment.urls.api}/backoffice/template/${id}/delete`
    );
  }
}
