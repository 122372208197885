import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AzOverlayComponent } from '@azigrene/components';
import { MiembroUsuario } from '@shared/models/home.model';
import { Observable } from 'rxjs';
import { AssigneeModel } from '@azigrene/components';
import { MiembroService } from '../../../../modules/frontoffice/services/miembro.service';
import { TareaService } from '../../../../modules/frontoffice/services/tarea.service';
import { map } from 'rxjs/operators';
import { Miembro, Usuario } from '@shared/models/auth.model';
import { TareaActions } from '../../../../modules/frontoffice/actions/TareaActions';

@Component({
  selector: 'app-assignee-selector-dropdown',
  template: `
    <az-assignees-circle-dropdown class="flex-1"
                                  [title]="'frontoffice.contrato.tareas.detalle.responsable' | transloco "
                                  [multiple]="false"
                                  [dropdown]="dropdown"
                                  [assigneeListObservable]="assigneeObs"
                                  (onChange)="updateAssignees($event)"
                                  [editable]="true" [value]="_assignees">
    </az-assignees-circle-dropdown>
  `,
  styles: []
})
export class ResponsableSelectorDropdownComponent implements OnInit, OnChanges {

  @Input() dropdown: AzOverlayComponent;
  @Input() contratoId: number;
  @Input() tareaId: number;
  @Input() responsable: Usuario;
  @Output() updateSuccess = new EventEmitter<MiembroUsuario[]>();

  assigneeObs: () => Observable<AssigneeModel[]>;
  _assignees: AssigneeModel[];

  constructor(private miembroService: MiembroService, private tareaService: TareaService) {
    this.assigneeObs = () => this.miembroService.all(this.contratoId).pipe(map((e: Miembro[]) => {
      return e.map(v => ({
        id: v.id,
        username: v.usuario.email
      }));
    }));
  }

  ngOnInit(): void {
    const user: Usuario = this.responsable;

    this._assignees = user ? [{ id: user.id, username: user.email }] : [];
  }

  updateAssignees(event: AssigneeModel[]): void {
    const members: number[] = event.map(e => e.id);

    this.tareaService.accionLista([this.tareaId], TareaActions.SET_RESPONSABLE, { miembroId: members[0] }).subscribe(n => {
      this.updateSuccess.emit();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.responsable) {
      const user: Usuario = changes.responsable.currentValue;

      this._assignees = user ? [{ id: user.id, username: user.email }] : [];
    }
  }

}
