import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {TranslocoService} from '@ngneat/transloco';
import cronstrue from 'cronstrue';
import {getPeriodicidadByCodigo, PeriodicidadEnum} from "@shared/enum/enums";
import {CronUtils, isPeriodicidadMensual} from "@shared/utils";

@Component({
  selector: 'app-periodicidad-form',
  templateUrl: './periodicidad-form.component.html',
})
export class PeriodicidadFormComponent implements OnInit {
  @Input() expresionCron: string;
  @Output() onChange = new EventEmitter<{cron: string; periodicidad: string}>();
  @Input() activePeriodicidad: PeriodicidadEnum;
  isPeriodicidadMensual = (periodicidad: string) => isPeriodicidadMensual(getPeriodicidadByCodigo(periodicidad));
  diasMes = Array.from(Array(31).keys()).map((i) => i + 1);
  cadaDias = Array.from(Array(29).keys()).map((i) => i + 2);
  cadaYear = Array.from(Array(9).keys()).map((i) => i + 2);
  cadaMonth = Array.from(Array(19).keys()).map((i) => i + 2);
  PeriodicidadEnum = PeriodicidadEnum;
  fechaSeleccionada: Date;
  diasSemanaSeleccionado: string[] = [];
  diaMesSeleccionado: any;
  cronParts: {
    day: number | string;
    week: string[];
    month: number | string;
    year: number | string;
    hour: number | string;
    minute: number | string;
  } = {
    day: '*',
    week: [],
    month: '*',
    year: '*',
    hour: 8,
    minute: 0,
  };
  months: {name: string; id: string}[] = [];
  valid: boolean;
  periodicidadOptions: any;
  monthMensual: string;
  dayDiario: string;
  yearAnual: string;

  cron = () =>
    cronstrue.toString(this.expresionCron, {
      locale: this.translateService.getActiveLang(),
      use24HourTimeFormat: true,
    });

  constructor(private translateService: TranslocoService) {
    this.translateService.selectTranslateObject('periodicidadForm.calendario.monthNames').subscribe((meses) => {
      this.months = [
        {id: 'JAN', name: meses[0]},
        {id: 'FEB', name: meses[1]},
        {id: 'MAR', name: meses[2]},
        {id: 'APR', name: meses[3]},
        {id: 'MAY', name: meses[4]},
        {id: 'JUN', name: meses[5]},
        {id: 'JUL', name: meses[6]},
        {id: 'AUG', name: meses[7]},
        {id: 'SEP', name: meses[8]},
        {id: 'OCT', name: meses[9]},
        {id: 'NOV', name: meses[10]},
        {id: 'DEC', name: meses[11]},
      ];
    });

    this.translateService.selectTranslateObject('dropdown-periodicidad.periodicidades').subscribe((per) => {
      this.periodicidadOptions = [
        {value: PeriodicidadEnum.DIARIO, label: per.diario},
        {value: PeriodicidadEnum.SEMANAL, label: per.semanal},
        {value: PeriodicidadEnum.MENSUAL, label: per.mensual},
        {value: PeriodicidadEnum.ANUAL, label: per.anual},
        {value: PeriodicidadEnum.PUNTUAL, label: per.puntual},
      ];
    });
  }

  ngOnInit() {
    if (this.isPeriodicidadMensual(this.activePeriodicidad)) {
      this.activePeriodicidad = PeriodicidadEnum.MENSUAL;
      this.monthMensual = this.cronParts.month.toString().length > 1 ? this.cronParts.month.toString().split('/')[1] : this.cronParts.month.toString();
    } else if (this.activePeriodicidad == PeriodicidadEnum.DIARIO) {
      this.dayDiario = this.cronParts.day.toString().length > 1 ? this.cronParts.day.toString().split('/')[1] : this.cronParts.day.toString();
    } else if (this.activePeriodicidad == PeriodicidadEnum.ANUAL) {
      this.yearAnual = this.cronParts.year.toString().length > 1 ? this.cronParts.year.toString().split('/')[1] : this.cronParts.year.toString();
    }
    this.loadExpression(this.expresionCron);
  }

  updatePeriodicty() {
    this.generarExpresionCron();
    this.onChange.emit({cron: this.expresionCron, periodicidad: this.activePeriodicidad});
  }

  seleccionarPeriodicidad(event) {
    this.resetValues();
    this.activePeriodicidad = event.value;
    this.generarExpresionCron();
  }

  seleccionarDiaSemana(event) {
    this.diasSemanaSeleccionado = event.value;
  }

  seleccionarUltimoDiaMes(checked) {
    if (checked) {
      this.diaMesSeleccionado = 'L';
    } else {
      this.diaMesSeleccionado = 1;
    }
    this.generarExpresionCron();
  }

  generarExpresionCron() {
    let generadorCron = {
      periodicidad: this.activePeriodicidad,
      cronParts: this.cronParts,
      parteVariable: [this.dayDiario, this.monthMensual, this.yearAnual],
      fechaPuntual: this.fechaSeleccionada
    };
    let resultado = CronUtils.generarExpresionCron(generadorCron);
    this.activePeriodicidad = resultado.periodicidad;
    this.expresionCron = resultado.cron;
    this.validate();
    return this.expresionCron;
  }

  loadExpression(expresionCron: string) {
    let elementosCron: string[];
    if (expresionCron) {
      elementosCron = expresionCron.split(' ').map((el) => el.trim());
      if (elementosCron.length === 7) {
        this.cronParts = {
          minute: elementosCron[1],
          hour: elementosCron[2],
          day: elementosCron[3],
          month: elementosCron[4],
          week: elementosCron[5].split(','),
          year: elementosCron[6],
        };
      }
      this.validate();
    }
  }

  resetValues() {
    this.cronParts = {
      day: '*',
      week: [],
      month: '*',
      year: '*',
      hour: 8,
      minute: 0,
    };
    this.diasSemanaSeleccionado = null;
    this.diaMesSeleccionado = null;
    this.fechaSeleccionada = null;
    this.expresionCron = null;
  }

  validate() {
    switch (this.activePeriodicidad) {
      case PeriodicidadEnum.DIARIO:
        if (!this.cronParts.day) {
          this.valid = false;
        } else {
          this.valid = true;
        }
        break;
      case PeriodicidadEnum.SEMANAL:
        if (!this.cronParts.week.length) {
          this.valid = false;
        } else {
          this.valid = true;
        }
        break;
      case PeriodicidadEnum.MENSUAL:
        if (!this.cronParts.month) {
          this.valid = false;
        } else {
          this.valid = true;
        }
        break;
      case PeriodicidadEnum.ANUAL:
        if (!this.cronParts.year) {
          this.valid = false;
        } else {
          this.valid = true;
        }
        break;
      case PeriodicidadEnum.PUNTUAL:
          this.valid = true;
        break;
    }
    return this.valid;
  }

  onChangeWeekday(weekday: 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN') {
    if (this.cronParts.week.includes(weekday)) {
      this.cronParts.week.splice(this.cronParts.week.indexOf(weekday), 1);
    } else {
      this.cronParts.week.push(weekday);
    }
    if (this.cronParts.week.length === 0) this.cronParts.week = ['?'];
    this.validate();
  }

  hasWeekday(weekday: 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN') {
    return this.cronParts.week.includes(weekday);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.expresionCron) {
      this.activePeriodicidad = this.isPeriodicidadMensual(this.activePeriodicidad) ? PeriodicidadEnum.MENSUAL : this.activePeriodicidad;
      this.loadExpression(changes.expresionCron.currentValue);
    }
  }

  onChangePeriodicidad(selectedPeriodicidad: string) {
    switch (selectedPeriodicidad) {
      case PeriodicidadEnum.DIARIO:
        break;
      case PeriodicidadEnum.SEMANAL:
        break;
      case PeriodicidadEnum.MENSUAL:
        break;
      case PeriodicidadEnum.ANUAL:
        this.cronParts = {
          day: 1,
          month: 1,
          ...this.cronParts,
        };
        break;
      case PeriodicidadEnum.PUNTUAL:
        break;
    }
  }

}
