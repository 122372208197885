import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {
  AssigneeModel,
  AzConfirmationModalService,
  AzModalConfig,
  AzModalRef,
  AzModalService,
  AzNotifierService,
  InfoNotificationType,
  ModalBaseComponent,
  NotificationType,
  TaggerModel
} from '@azigrene/components';
import {
  DataItemRequest,
  DataListSearchRequest,
  DataPaginatedSearchRequest,
  PaginatedResponse
} from '@azigrene/searchrequest';
import {TranslocoService} from '@ngneat/transloco';
import {Select} from '@ngxs/store';
import {EstadoRevisionConfig} from '@shared/components/tag-estado-revision/tag-estado-revision.component';
import {LoginResponse, Miembro, MiembroTarea} from '@shared/models/auth.model';
import {EnumItem} from '@shared/models/generic.model';
import {Documento, DocumentoRevision, HistoricoRevisionTarea, Revision} from '@shared/models/home.model';
import {AuthState} from '@store/auth/auth.store';
import {saveAs} from 'file-saver';
import {forkJoin, Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {TareaActions} from '../../actions/TareaActions';
import {DocumentoService} from '../../services/documento.service';
import {EnumService} from '../../services/enum.service';
import {EtiquetaService} from '../../services/etiqueta.service';
import {HistoricoRevisionService} from '../../services/historico-revision.service';
import {MiembroService} from '../../services/miembro.service';
import {RevisionService} from '../../services/revision.service';
import {TareaService} from '../../services/tarea.service';
import {TareaDetalleComponent} from '../tarea-detalle/tarea-detalle.component';
import {
  RevisionSubirDocumentoComponent
} from "../../pages/revision/dialogs/revision-subir-documento/revision-subir-documento.dialog";
import cronstrue from 'cronstrue/i18n';
import {CronUtils} from "@shared/utils";
import {PeriodicidadVisual} from "@shared/enum/enums";
import {Etiqueta} from '@shared/models/etiqueta.model';

@Component({
  selector: 'app-revision-dialog',
  templateUrl: './revision-dialog.component.html',
  styles: [],
})
export class RevisionDialogComponent extends ModalBaseComponent implements OnInit, AfterViewInit {
  @ViewChild('revisionModal') revisionModal;
  @Select(AuthState.getUser) user$: Observable<LoginResponse>;
  cronDescribe = (str) => cronstrue.toString(str, {locale: 'es'});

  revision: Revision;
  revisionRequest: DataItemRequest<Revision>;
  estadosRevisionConfig = EstadoRevisionConfig;
  documentosRequest: DataListSearchRequest<DocumentoRevision>;
  activeRevisionId: number;

  revisionesFn: (sr) => Observable<PaginatedResponse<Revision>>;
  estadosFn: Observable<EnumItem[]>;
  subestados: EnumItem[] = [];

  historicosRevisionRequest: DataPaginatedSearchRequest<HistoricoRevisionTarea>;
  assigneeObs: () => Observable<AssigneeModel[]>;
  assignees: AssigneeModel[];

  tags: TaggerModel[];
  tagObs: () => Observable<TaggerModel[]>;
  tagUpdObs: (tag: TaggerModel) => Observable<TaggerModel>;
  tagCreateObs: (tag: TaggerModel) => Observable<TaggerModel>;
  tagDeleteObs: (tagId: number) => Observable<boolean>;
  commentText: string;
  updateCommentText: string;
  editingComment: number;
  periodicidadVisual: PeriodicidadVisual;

  constructor(
    public ref: AzModalRef,
    public config: AzModalConfig,
    private translateService: TranslocoService,
    private tareaService: TareaService,
    private modalService: AzModalService,
    private notifier: AzNotifierService,
    private revisionService: RevisionService,
    private historicoRevisionService: HistoricoRevisionService,
    private documentoService: DocumentoService,
    private etiquetasService: EtiquetaService,
    private miembrosService: MiembroService,
    private enumService: EnumService,
    private confirmationService: AzConfirmationModalService, private _el: ElementRef) {
    super(config, ref);
    this.revisionesFn = (sr) => this.revisionService.allByTarea(this.revision.contrato.id, this.revision.tarea.id, sr);
    this.estadosFn = this.enumService.estadosRevision();

    this.revisionRequest = new DataItemRequest<Revision>(() => this.revisionService.getOne(this.config.data.contratoId, this.config.data.tareaId, this.config.data.revisionId));
    this.revisionRequest.onSuccess.subscribe((revision) => {
      this.revision = revision;
      this.periodicidadVisual = CronUtils.getPeriocidadVisual(revision.tarea.periodicidad, revision.tarea.expresionCron);
      this.loadSubestados(revision.estadoId, revision.tipoContrato?.esquemaEstados);
      this.activeRevisionId = this.revision.id;
      this.historicosRevisionRequest.load();
      this.tags = this.revision.etiquetasArray
        ? this.revision.etiquetasArray.jsonArray.map((v) => ({
          id: v.id,
          label: v.nombre,
          color: v.color,
        }))
        : [];
      this.assignees = this.revision.miembrosArray
        ? this.revision.miembrosArray.jsonArray.map((v) => ({
          id: v.miembroId,
          username: v.email,
        }))
        : [];
    });

    this.documentosRequest = new DataListSearchRequest<DocumentoRevision>(() => this.revisionService.getAllDocuments(this.config.data.contratoId, this.config.data.tareaId, this.config.data.revisionId, this.documentosRequest.searchRequest));
    this.documentosRequest.searchRequest.addSort('created_at', 'DESC', false);

    this.historicosRevisionRequest = new DataPaginatedSearchRequest<HistoricoRevisionTarea>(() =>
      this.historicoRevisionService.getAll(this.revision.contrato.id, this.revision.tarea.id, this.activeRevisionId, this.historicosRevisionRequest.searchRequest)
    );
    this.historicosRevisionRequest.searchRequest.addSort('created_at', 'ASC', false);
    this.initObservables();
  }

  ngAfterViewInit(): void {
    this.revisionModal.nativeElement.focus();
  }

  private loadSubestados(estadoId: number, schemas: string[]): void {
    const subs = {};
    if(!schemas) {
      return;
    }
    for (const schema of schemas) {
      subs[schema] = this.enumService.subestadosRevisionSchema(estadoId, schema);
    }

    forkJoin(subs).subscribe((mapResult: Map<string, EnumItem[]>) => {
      const subestadosArray = [];

      Object.values(mapResult).forEach((items) => {
        items.forEach((item) => {
          if (subestadosArray.indexOf(item) < 0) {
            subestadosArray.push(item);
          }
        });
      });

      this.subestados = subestadosArray;
    });
  }

  private initObservables() {
    this.tagObs = () =>
      this.etiquetasService.getAll(this.revision.contrato.id).pipe(
        map((e: Etiqueta[]) => {
          return e.map((v) => ({
            id: v.id,
            label: v.nombre,
            color: v.color,
          }));
        })
      );
    this.tagCreateObs = (tag: TaggerModel) =>
      this.etiquetasService
        .create( {
          id: null,
          contratoId: this.revision.contrato.id,
          color: tag.color,
          nombre: tag.label,
        }, this.revision.contrato.id)
        .pipe(
          map((v: Etiqueta) => {
            return {
              id: v.id,
              label: v.nombre,
              color: v.color,
            };
          })
        );
    this.tagUpdObs = (tag: TaggerModel) =>
      this.etiquetasService
        .update({
          id: tag.id,
          contratoId: this.revision.contrato.id,
          color: tag.color,
          nombre: tag.label,
        }, this.revision.contrato.id)
        .pipe(
          map((v: Etiqueta) => {
            return {
              id: v.id,
              label: v.nombre,
              color: v.color,
            };
          })
        );
    this.tagDeleteObs = (tagId) =>
      this.etiquetasService.delete(tagId, this.revision.contrato.id).pipe(
        map((isDeleted: boolean) => isDeleted)
      );

    this.assigneeObs = () =>
      this.miembrosService.all(this.revision.contrato.id).pipe(
        map((e: Miembro[]) => {
          return e.map((v) => ({
            id: v.id,
            username: v.usuario.email,
          }));
        })
      );
  }

  fetch(): void {
    this.revisionRequest.load();
  }

  ngOnInit(): void {

    if (this.config.data.contratoId && this.config.data.tareaId && this.config.data.revisionId) {
      this.revisionRequest.load();
      this.documentosRequest.load();
    }
  }

  openDialogoDocumento(): void {
    const ref = this.modalService.open(RevisionSubirDocumentoComponent, {
      styleClass: 'w-full md:w-1/3',
      data: {
        contratoId: this.config.data.contratoId,
        tareaId: this.config.data.tareaId,
        revisionId: this.config.data.revisionId,
      },
    });

    ref.onClose.pipe(take(1)).subscribe(() => {
      this.documentosRequest.load();
      this.revisionRequest.load();
    });
  }

  downloadDoc(documento: Documento): void {
    this.documentoService.downloadDocument(documento.id).subscribe((data) => {
      saveAs(data, documento.filename);
    });
  }

  deleteDoc(documento: Documento): void {
    this.confirmationService.open({
      showCancel: true,
      cancelText: this.translateService.translate('actions.cancel'),
      confirmationText: this.translateService.translate('frontoffice.dialogs.revision.document-confirm-delete', {
        value: documento.nombre,
      }),
      accept: () => {
        this.documentoService.deleteDocument(documento.id).subscribe(() => {
          this.notifier.showInfoError('Documento eliminado');
          this.documentosRequest.load();
          this.revisionRequest.load();
        });
      },
    });
  }

  updateTags(event: TaggerModel[]): void {
    const etiquetas: Etiqueta[] = event.map((e) => ({nombre: e.label, color: e.color, id: e.id}));

    this.tareaService.accionLista([this.revision.tarea.id], TareaActions.SET_TAGS, {tags: etiquetas}).subscribe((n) => {
      if (n) {
        this.revisionRequest.load();
      }
    });
  }

  updatePriority(id: number): void {
    this.tareaService.accionLista([this.revision.tarea.id], TareaActions.SET_PRIORITY, {priority: id}).subscribe((n) => {
      if (n) {
        this.revisionRequest.load();
      }
    });
  }

  updateAssignees(event: AssigneeModel[]): void {
    const members: MiembroTarea[] = event.map((e) => ({
      miembroId: e.id,
      tareaId: this.revision.tarea.id,
    }));

    this.tareaService.accionLista([this.revision.tarea.id], TareaActions.SET_ASSIGNEES, {assignees: members}).subscribe((n) => {
      if (n) {
        this.revisionRequest.load();
      }
    });
  }

  openTareaDialog(): void {
    this.modalService.open(TareaDetalleComponent, {
      showHeader: false,
      data: {
        tarea: this.revision.tarea,
        loadTarea: true,
      },
      styleClass: 'w-full md:max-w-4xl',
    });
  }

  createComment(value: string): void {
    this.revisionService.createComentario(this.revision.contrato.id, this.revision.tarea.id, this.revision.id, {comentario: value}).subscribe(
      () => {
        this.historicosRevisionRequest.load();
      },
      (error) => {
        this.notifier.showInfoError(error.error.message);
      }
    );
  }

  updateComment(history: HistoricoRevisionTarea, value: string): void {
    this.historicoRevisionService.update(history.id, this.revision.contrato.id, this.revision.tarea.id, this.revision.id, {comentario: value}).subscribe(
      () => {
        this.historicosRevisionRequest.load();
      },
      (error) => {
        this.notifier.showInfoError(error.error.message);
      }
    );
  }

  updateStatus(statusId: number, item: Revision, substatusId?: number): void {
    this.revisionService.updateEstado(item.contrato.id, item.tareaId, item.id, {
      estadoId: statusId,
      subestadoId: substatusId || null
    }).subscribe(
      () => {
        this.notifier.show(NotificationType.ACTION, this.translateService.translate('misc.notifier.dato-actualizado'), null, {
          key: 'messages',
          infoType: InfoNotificationType.SUCCESS,
        });
        this.revisionRequest.load();
      },
      () => {
        this.notifier.showInfoError('misc.notifier.update-error');
      }
    );
  }

  changeRevision(event: Revision): void {
    this.activeRevisionId = event.id;
    this.historicosRevisionRequest.load();
  }

  delete(history: HistoricoRevisionTarea): void {
    this.historicoRevisionService.delete(history.id, this.revision.contrato.id, this.revision.tarea.id, this.revision.id).subscribe(() => this.historicosRevisionRequest.load());
  }

}
