import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {EnumItem} from '@shared/models/generic.model';
import {Etiqueta} from '@shared/models/etiqueta.model';
import {Observable} from "rxjs";

@Injectable()
export class EnumService {

  constructor(private http: HttpClient) {}

  estadosRevision(): Observable<EnumItem[]> {
    return this.http.get<EnumItem[]>(`${environment.urls.api}/enumEstadoRevision`);
  }
  subestadosRevision(estadoId: number): Observable<EnumItem[]> {
    return this.http.post<EnumItem[]>(`${environment.urls.api}/enumSubestado`, estadoId);
  }
  subestadosRevisionSchema(estadoId: number, schema: string): Observable<EnumItem[]> {
    return this.http.post<EnumItem[]>(`${environment.urls.api}/enumSubestado/${schema}`, estadoId);
  }
  tiposContrato(): Observable<EnumItem[]> {
    return this.http.get<EnumItem[]>(`${environment.urls.api}/enumTipoContrato`);
  }
  roles(): Observable<EnumItem[]> {
    return this.http.get<EnumItem[]>(`${environment.urls.api}/enumRol`);
  }
  plantillasContrato(): Observable<EnumItem[]> {
    return this.http.get<EnumItem[]>(`${environment.urls.api}/enumPlantillaContrato`);
  }
  plantillasContratoByTipo(tipoContratoId: number): Observable<EnumItem[]> {
    return this.http.get<EnumItem[]>(`${environment.urls.api}/enumPlantillaContrato/${tipoContratoId}`);
  }
  estadosRevisionEstado(): Observable<EnumItem[]> {
    return this.http.post<EnumItem[]>(`${environment.urls.api}/enumEstadosRevisionEstado`, {});
  }

}
