import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DocumentoTarea, Revision, Tarea } from '@shared/models/home.model';
import { EntregableTarea } from '@shared/models/entregable.model';
import { UploadDocumento } from '@shared/models/generic.model';
import { PaginatedResponse, SearchRequest } from '@azigrene/searchrequest';
import { Observable } from "rxjs";
import {ResultadoValidacionModel} from "@shared/models/resultado-validacion.model";

@Injectable()
export class TareaService {
  constructor(private http: HttpClient) {
  }

  getRevisionesById(contratoId: number, tareaId: number, searchRequest: SearchRequest) {
    return this.http.post<PaginatedResponse<Revision>>(`${environment.urls.api}/contracts/${contratoId}/tasks/${tareaId}/revisions`, searchRequest);
  }

  getOne(contratoId: number, tareaId: number) {
    return this.http.get<Tarea>(`${environment.urls.api}/contracts/${contratoId}/tasks/${tareaId}`);
  }

  all(id: number, searchRequest: SearchRequest = new SearchRequest()) {
    return this.http.post<PaginatedResponse<Tarea>>(`${environment.urls.api}/contracts/${id}/tasks/all`, searchRequest);
  }

  getAllByResponsable(searchRequest: SearchRequest = new SearchRequest()) {
    return this.http.post<Tarea[]>(`${environment.urls.api}/contracts/0/tasksByResponsable`, searchRequest);
  }

  getAllByAsignacion(searchRequest: SearchRequest = new SearchRequest()) {
    return this.http.post<Tarea[]>(`${environment.urls.api}/contracts/0/tasksByAsignacion`, searchRequest);
  }

  insert(contratoId: number, data: Tarea | any) {
    return this.http.post<Tarea[]>(`${environment.urls.api}/contracts/${contratoId}/tasks/create`, data);
  }

  update(tareaId: number, contratoId: number, data: Tarea) {
    return this.http.post<Tarea>(`${environment.urls.api}/contracts/${contratoId}/tasks/${tareaId}/update`, data);
  }

  getAllDocuments(idContrato: number, idTarea: number, searchRequest: SearchRequest = new SearchRequest()) {
    return this.http.post<DocumentoTarea[]>(`${environment.urls.api}/contracts/${idContrato}/tasks/${idTarea}/docs/list`, searchRequest);
  }

  uploadDocument(idContrato: number, idTarea: number, datos: UploadDocumento) {
    const input = new FormData();
    input.append('file', datos.file);
    input.append('nombre', datos.nombre);
    input.append('observaciones', datos.observaciones);
    const req = new HttpRequest('POST', `${environment.urls.api}/contracts/${idContrato}/tasks/${idTarea}/docs/create`, input);
    return this.http.request(req);
  }

  delete(idContrato: number, idTarea: number) {
    return this.http.delete<Tarea>(`${environment.urls.api}/contracts/${idContrato}/tasks/${idTarea}/delete`);
  }

  getEntregables(contratoId, tareaId, searchRequest = new SearchRequest()) {
    return this.http.post<EntregableTarea[]>(
      `${environment.urls.api}/contracts/${contratoId}/tasks/${tareaId}/deliverables`,
      searchRequest
    );
  }


  accionMasiva(searchRequest: SearchRequest, accion, data: any) {
    return this.http.post(`${environment.urls.api}/tasks/accion-masiva`, {
      searchRequest,
      accion,
      data
    });
  }

  accionLista(ids: number[], accion, data: any) {
    return this.http.post(`${environment.urls.api}/tasks/accion-lista`, { ids, accion, data });
  }

  export(contratoId: number, searchRequest: SearchRequest, format?: string) {
    if (format){
      return this.http.post(`${environment.urls.api}/contracts/${contratoId}/tasks/export/${format}`, searchRequest, { responseType: 'blob', observe: 'response' });
    } else {
      return this.http.post(`${environment.urls.api}/contracts/${contratoId}/tasks/export`, searchRequest, { responseType: 'blob', observe: 'response' });
    }
  }

  import(contratoId: number, file: unknown, format?: string) {
    if (format) {
      return this.http.post(`${environment.urls.api}/contracts/${contratoId}/tasks/import/${format}`, file);
    } else {
      return this.http.post(`${environment.urls.api}/contracts/${contratoId}/tasks/import`, file);
    }
  }

  exportPeriodicity(contratoId: number) {
    return this.http.get(`${environment.urls.api}/contracts/${contratoId}/tasks/periodicity/export`, { responseType: 'blob', observe: 'response' });
  }

  importPeriodicity(contratoId: number, file: unknown): Observable<ResultadoValidacionModel> {
    return this.http.post<ResultadoValidacionModel>(`${environment.urls.api}/contracts/${contratoId}/tasks/periodicity/import`, file);
  }

}
