import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { AssigneeModel } from '@azigrene/components';
import { MiembroService } from '../../../../modules/frontoffice/services/miembro.service';
import { Miembro } from '@shared/models/auth.model';
import { TareaService } from '../../../../modules/frontoffice/services/tarea.service';
import { map } from 'rxjs/operators';
import { TareaActions } from '../../../../modules/frontoffice/actions/TareaActions';

@Component({
  selector: 'app-assignee-selector',
  template: `
    <az-assignee-single class='flex-1'
                        [assigneeListObservable]='assigneeObs'
                        (ngModelChange)='updateAssignee($event)'
                        [editable]='true' [ngModel]='_assignee'>
    </az-assignee-single>
  `,
  styles: []
})
export class AssigneeSelectorComponent implements OnInit, OnChanges {

  @Input() contratoId: number;
  @Input() tareaId: number;
  @Input() assignee: { id: number, username: string };
  @Output() updateSuccess = new EventEmitter();

  assigneeObs: () => Observable<AssigneeModel[]>;
  _assignee: AssigneeModel;

  constructor(private miembroService: MiembroService, private tareaService: TareaService) {
  }

  ngOnInit(): void {
    this.assigneeObs = () => this.miembroService.all(this.contratoId).pipe(map((e: Miembro[]) => {
      return e.map(v => ({
        id: v.id,
        username: v.usuario.email
      }));
    }));
  }

  updateAssignee(event: AssigneeModel): void {
    this.tareaService.accionLista([this.tareaId], TareaActions.SET_RESPONSABLE, { miembroId: event.id }).subscribe(n => {
      if (n) {
        this.updateSuccess.emit();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.assignee) {
      this._assignee = changes.assignee.currentValue ? changes.assignee.currentValue : null;
    }
  }

}
