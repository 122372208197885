import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginatedResponse, SearchRequest } from '@azigrene/searchrequest';
import { Subestado } from '@shared/models/home.model';
import { environment } from '../../../../environments/environment';

@Injectable()
export class BackofficeSubestadoService {
  constructor(private http: HttpClient) {}

  all(searchRequest: SearchRequest = new SearchRequest()) {
    return this.http.post<Subestado[]>(`${environment.urls.api}/backoffice/substatesNoPagination`, searchRequest);
  }

  allPaginated(searchRequest: SearchRequest = new SearchRequest()) {
    return this.http.post<PaginatedResponse<Subestado>>(`${environment.urls.api}/backoffice/substates`, searchRequest);
  }

  one(id: number) {
    return this.http.get<Subestado>(`${environment.urls.api}/backoffice/substates/${id}`);
  }

  create(data: Subestado) {
    return this.http.post<Subestado>(`${environment.urls.api}/backoffice/substates/create`, data);
  }

  update(id: number, data: Subestado) {
    return this.http.post<Subestado>(
      `${environment.urls.api}/backoffice/substates/${id}/update`,
      data
    );
  }
  delete(id: number) {
    return this.http.delete<Subestado>(`${environment.urls.api}/backoffice/substates/${id}/delete`);
  }
}
