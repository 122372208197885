import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { TranslocoService } from '@ngneat/transloco';
import moment from 'moment';
import { TareaActions } from '../../../../modules/frontoffice/actions/TareaActions';
import { TareaService } from '../../../../modules/frontoffice/services/tarea.service';
import {PeriodicidadEnum} from "@shared/enum/enums";
import {Frecuencia} from "@shared/utils";

@Component({
  selector: 'app-periodicity-selector',
  template: `
    <az-overlay #dd class="flex my-1 w-full " [template]="tpl" dropdownClass="max-w-sm w-full">
      <ng-container *ngIf="periodicidadSeleccionada">
        <div class="cursor-pointer h-7 w-full px-2 rounded-sm bg-gray-100 flex items-center justify-center space-x-2">
          <svg class="w-3 h-3 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path>
          </svg>
          <span>{{ periodicidadSeleccionada }}</span>
        </div>
      </ng-container>
      <div *ngIf="!periodicidadSeleccionada"
           class="cursor-pointer h-7 px-2 rounded-sm bg-gray-100 flex items-center justify-center">
        <svg class="w-4 h-4 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
        </svg>
      </div>
    </az-overlay>

    <ng-template #tpl>
      <app-periodicity-dropdown [dropdown]="dd" [activePeriodicidad]="periodicidadSeleccionada"
                                [expresionCron]="expresionCron" [tareaId]="tareaId" (onChange)="onChange.emit($event)"
                                (onUpdateSuccess)="onUpdateSuccess.emit($event);periodicityChange($event);"
                                [contratoId]="contratoId" [frecuencia]="frecuencia"></app-periodicity-dropdown>
    </ng-template>
  `,
  styles: []
})
export class PeriodicitySelectorComponent implements OnInit {
  @Input() contratoId: number;
  @Input() tareaId: number;
  @Input() expresionCron: string;
  @Input() periodicidadSeleccionada: string;
  @Input() frecuencia: Frecuencia;
  @Output() onChange = new EventEmitter();
  @Output() onUpdateSuccess = new EventEmitter();


  periodicidadOptions: SelectItem[];
  diasSemanaOptions: SelectItem[];
  fechaSeleccionada: Date;
  diasSemanaSeleccionado: string[] = [];
  diaMesSeleccionado: any;
  mesSeleccionado: Date;
  diaPersonalizado: number = null;
  diaP_checked: boolean = false;
  mesPersonalizado: number = null;
  mesP_checked: boolean = false;
  anyoPersonalizado: number = null;
  anyoP_checked: boolean = false;
  ultimoChecked: boolean = false;
  momentDaysOfWeek: string[];
  valid: boolean;

  constructor(private translateService: TranslocoService, private tareaService: TareaService) {
    this.momentDaysOfWeek = moment.weekdays(true);
  }

  ngOnInit() {
    this.periodicidadOptions = [
      {
        value: PeriodicidadEnum.DIARIO,
        label: this.translateService.translate('periodicidadForm.periodicidades.diario')
      },
      {
        value: PeriodicidadEnum.SEMANAL,
        label: this.translateService.translate('periodicidadForm.periodicidades.semanal')
      },
      {
        value: PeriodicidadEnum.MENSUAL,
        label: this.translateService.translate('periodicidadForm.periodicidades.mensual')
      },
      {
        value: PeriodicidadEnum.ANUAL,
        label: this.translateService.translate('periodicidadForm.periodicidades.anual')
      },
      {
        value: PeriodicidadEnum.PUNTUAL,
        label: this.translateService.translate('periodicidadForm.periodicidades.puntual')
      }
    ];

    this.diasSemanaOptions = [
      {
        value: 'MON',
        label: this.translateService.translate('periodicidadForm.diasSemana.lunes')
      },
      {
        value: 'TUE',
        label: this.translateService.translate('periodicidadForm.diasSemana.martes')
      },
      {
        value: 'WED',
        label: this.translateService.translate('periodicidadForm.diasSemana.miercoles')
      },
      {
        value: 'THU',
        label: this.translateService.translate('periodicidadForm.diasSemana.jueves')
      },
      {
        value: 'FRI',
        label: this.translateService.translate('periodicidadForm.diasSemana.viernes')
      },
      {
        value: 'SAT',
        label: this.translateService.translate('periodicidadForm.diasSemana.sabado')
      },
      {
        value: 'SUN',
        label: this.translateService.translate('periodicidadForm.diasSemana.domingo')
      }
    ];

    this.fillForm(this.expresionCron);
  }

  seleccionarPeriodicidad(event) {
    this.resetValues();
    this.periodicidadSeleccionada = event.value;
    this.generarExpresion();
  }

  seleccionarDiaSemana(event) {
    this.diasSemanaSeleccionado = event.value;
  }

  seleccionarUltimoDiaMes(checked) {
    if (checked) this.diaMesSeleccionado = 'L';
    else this.diaMesSeleccionado = 1;
    this.generarExpresion();
  }

  generarExpresion() {
    switch (this.periodicidadSeleccionada) {
      case PeriodicidadEnum.DIARIO:
        if (this.diaP_checked && this.diaPersonalizado) this.expresionCron = `0 0 8 */${this.diaPersonalizado} * ? *`;
        else this.expresionCron = '0 0 8 * * ? *';
        break;
      case PeriodicidadEnum.SEMANAL:
        if (this.diasSemanaSeleccionado) {
          this.expresionCron = `0 0 8 ? * ${this.diasSemanaSeleccionado.toString()} *`;
        }
        break;
      case PeriodicidadEnum.MENSUAL:
        if (this.diaMesSeleccionado) {
          if (this.mesP_checked && this.mesPersonalizado) this.expresionCron = `0 0 8 ${this.diaMesSeleccionado} */${this.mesPersonalizado} ? *`;
          else this.expresionCron = `0 0 8 ${this.diaMesSeleccionado} * ? *`;
        }
        break;
      case PeriodicidadEnum.ANUAL:
        if (this.diaMesSeleccionado && this.mesSeleccionado) {
          if (this.mesP_checked && this.mesPersonalizado) this.expresionCron = `0 0 8 ${this.diaMesSeleccionado} ${this.mesSeleccionado.getMonth() + 1} ? */${this.mesPersonalizado}`;
          else this.expresionCron = `0 0 8 ${this.diaMesSeleccionado} ${this.mesSeleccionado.getMonth() + 1} ? *`;
        }
        break;
      case PeriodicidadEnum.PUNTUAL:
        if (this.fechaSeleccionada) {
          if (this.anyoP_checked) {
            if (this.mesP_checked) {
              if (this.diaP_checked) {
                this.expresionCron = `0 0 8 ${this.fechaSeleccionada.getDate()}/${this.diaPersonalizado} ${(this.fechaSeleccionada.getMonth() + 1)}/${this.mesPersonalizado} ? ${this.fechaSeleccionada.getFullYear()}/${this.anyoPersonalizado}`;
              } else {
                this.expresionCron = `0 0 8 ${this.fechaSeleccionada.getDate()} ${(this.fechaSeleccionada.getMonth() + 1)}/${this.mesPersonalizado} ? ${this.fechaSeleccionada.getFullYear()}/${this.anyoPersonalizado}`;
              }
            } else {
              this.expresionCron = `0 0 8 ${this.fechaSeleccionada.getDate()} ${(this.fechaSeleccionada.getMonth() + 1)} ? ${this.fechaSeleccionada.getFullYear()}/${this.anyoPersonalizado}`;
            }
          } else {
            this.expresionCron = `0 0 8 ${this.fechaSeleccionada.getDate()} ${(this.fechaSeleccionada.getMonth() + 1)} ? ${this.fechaSeleccionada.getFullYear()}`;
          }
        }
        break;
    }
    this.validar();
    return this.expresionCron;
  }

  resetValues() {
    this.diasSemanaSeleccionado = null;
    this.diaMesSeleccionado = null;
    this.ultimoChecked = false;
    this.diaPersonalizado = null;
    this.diaP_checked = false;
    this.mesSeleccionado = null;
    this.mesPersonalizado = null;
    this.mesP_checked = false;
    this.anyoPersonalizado = null;
    this.anyoP_checked = false;
    this.fechaSeleccionada = null;
    this.expresionCron = null;
  }

  validar() {
    switch (this.periodicidadSeleccionada) {
      case PeriodicidadEnum.DIARIO:
        this.valid = true;
        break;
      case PeriodicidadEnum.SEMANAL:
        if (!this.diasSemanaSeleccionado) this.valid = false;
        else this.valid = true;
        break;
      case PeriodicidadEnum.MENSUAL:
        if (!this.diaMesSeleccionado) this.valid = false;
        else this.valid = true;
        break;
      case PeriodicidadEnum.ANUAL:
        if (!this.mesSeleccionado || !this.diaMesSeleccionado) this.valid = false;
        else this.valid = true;
        break;
      case PeriodicidadEnum.PUNTUAL:
        if (!this.fechaSeleccionada) this.valid = false;
        else this.valid = true;
        break;
    }

    this.onChange.emit(this.expresionCron + ';' + this.periodicidadSeleccionada);
    return this.valid;
  }

  updatePeriodicty() {
    this.tareaService.accionLista([this.tareaId], TareaActions.SET_PERIODICITY, {
      cron: this.expresionCron,
      periodicidad: this.periodicidadSeleccionada
    }).subscribe(n => {
      if (n) this.onUpdateSuccess.emit();
    });
  }

  periodicityChange(value: { cron: string, periodicidad: string }) {
    this.periodicidadSeleccionada = value.periodicidad;
    this.fillForm(value.cron);
  }

  fillForm(expresionCron: string) {
    let elementosCron: string[];
    if (expresionCron) {
      elementosCron = expresionCron.split(' ').map(el => el.trim());
      if (elementosCron.length != 7) {
        this.periodicidadSeleccionada = PeriodicidadEnum.PUNTUAL;
        return;
      }
      if (elementosCron[6] != '*' && !elementosCron[6].includes('/') && elementosCron[4] != '*' && elementosCron[4].length == 1 && elementosCron[3] != '*') {
        this.periodicidadSeleccionada = PeriodicidadEnum.PUNTUAL;
      } else if (elementosCron[6].includes('/')) {
        this.periodicidadSeleccionada = PeriodicidadEnum.ANUAL;
      } else if (elementosCron[4].includes('/')) {
        let cronMes = parseInt(elementosCron[4].split('/')[1]);
        switch (cronMes) {
          case 2:
            this.periodicidadSeleccionada = PeriodicidadEnum.BIMESTRAL;
            break;
          case 3:
            this.periodicidadSeleccionada = PeriodicidadEnum.TRIMESTRAL;
            break;
          case 4:
            this.periodicidadSeleccionada = PeriodicidadEnum.CUATRIMESTRAL;
            break;
          case 6:
            this.periodicidadSeleccionada = PeriodicidadEnum.SEMESTRAL;
            break;
          default:
            this.periodicidadSeleccionada = PeriodicidadEnum.MENSUAL;
            break;
        }
      } else if (elementosCron[3].includes('/')) {
        this.periodicidadSeleccionada = PeriodicidadEnum.DIARIO;
      } else if (elementosCron[5] != '*' && elementosCron[5] != '?' && elementosCron[6] == '*') {
        this.periodicidadSeleccionada = PeriodicidadEnum.SEMANAL;
      } else if (elementosCron[6] == '*') {
        if (elementosCron[4] == '*') {
          if (elementosCron[3] == '*') {
            this.periodicidadSeleccionada = PeriodicidadEnum.DIARIO;
          } else {
            this.periodicidadSeleccionada = PeriodicidadEnum.MENSUAL;
          }
        } else {
          this.periodicidadSeleccionada = PeriodicidadEnum.ANUAL;
        }
      }
    }
  }
}
