import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Contratante } from '@shared/models/home.model';

@Injectable()
export class ContratanteService {
  constructor(private http: HttpClient) {}

  all(){
    return this.http.get<Contratante[]>(`${environment.urls.api}/backoffice/contractors`);
  }

  getAll(searchRequest: any = {}) {
    return this.http.get<Contratante[]>(`${environment.urls.api}/contractor/list`);
  }
}
