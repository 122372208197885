import { IAPPConfig } from './iconfig';

export const appConfig: IAPPConfig = {
  apiUrl: window['env']['apiUrl'] || 'http://localhost:8090',
  webUrl: window['env']['webUrl'] || 'http://localhost:4200'
};

export const apiEndpoints = {
  etiqueta: {
    maestro: appConfig.apiUrl + '/tags',
    usuario: appConfig.apiUrl + '/contracts/${idContrato}/tags'
  }
}
