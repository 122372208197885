import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Contratante } from '@shared/models/home.model';
import { PaginatedResponse, SearchRequest } from '@azigrene/searchrequest';

@Injectable()
export class BackofficeContratanteService {
  constructor(private http: HttpClient) {
  }

  all() {
    return this.http.get<Contratante[]>(`${environment.urls.api}/backoffice/contractors`);
  }

  allPaginated(searchRequest: SearchRequest) {
    return this.http.post<PaginatedResponse<Contratante>>(
      `${environment.urls.api}/backoffice/contractors`,
      searchRequest
    );
  }

  one(id: number) {
    return this.http.get<Contratante>(`${environment.urls.api}/backoffice/contractors/${id}`);
  }

  create(data: Contratante) {
    return this.http.post<Contratante>(
      `${environment.urls.api}/backoffice/contractors/create`,
      data
    );
  }

  update(id: number, data: Contratante) {
    return this.http.post<Contratante>(
      `${environment.urls.api}/backoffice/contractors/${id}/update`,
      data
    );
  }

  delete(id: number) {
    return this.http.delete<Contratante>(
      `${environment.urls.api}/backoffice/contractors/${id}/delete`
    );
  }
}
