import { Injectable } from '@angular/core';
import { Tarea } from '@shared/models/home.model';
import { AssigneeModel, TaggerModel } from '@azigrene/components';
import { TareaActions } from '../actions/TareaActions';
import { TareaService } from './tarea.service';
import {Etiqueta} from "@shared/models/etiqueta.model";

@Injectable()
export class TareaActionService {
  constructor(private taskService: TareaService) {
  }

  updateTags(task: Tarea, event: TaggerModel[]) {
    const etiquetas: Etiqueta[] = event.map(e => ({ nombre: e.label, color: e.color, id: e.id }));
    return this.taskService.accionLista([task.id], TareaActions.SET_TAGS, { tags: etiquetas });
  }

  updatePriority(task: Tarea, id: number) {
    return this.taskService.accionLista([task.id], TareaActions.SET_PRIORITY, { prioridadId: id });
  }

  updateAssignees(task: Tarea, event: AssigneeModel[]) {
    const members: number[] = event.map(e => e.id);
    return this.taskService.accionLista([task.id], TareaActions.SET_ASSIGNEES, { assignees: members });
  }

  updateListTags(projectId: number, tasksIds: number[], event: TaggerModel[]) {
    const etiquetas: Etiqueta[] = event.map(e => ({ nombre: e.label, color: e.color, id: e.id }));
    return this.taskService.accionLista(tasksIds, TareaActions.SET_TAGS, { tags: etiquetas });
  }

  updateListPriority(projectId: number, tasksIds: number[], priorityId: number) {
    return this.taskService.accionLista(tasksIds, TareaActions.SET_PRIORITY, { prioridadId: priorityId });
  }

  updateListAssignees(projectId: number, tasksIds: number[], event: AssigneeModel[]) {
    const members: number[] = event.map(e => e.id);
    return this.taskService.accionLista(tasksIds, TareaActions.SET_ASSIGNEES, { assignees: members });
  }
}
