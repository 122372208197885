import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { PaginatedResponse, SearchRequest } from '@azigrene/searchrequest';
import { TareaPredefinida } from '@shared/models/home.model';
import { UploadDocumento } from '@shared/models/generic.model';

@Injectable()
export class BackofficeTareaPredefinidaService {
  constructor(private http: HttpClient) {
  }

  all(tipoContratoId: number) {
    return this.http.get<TareaPredefinida[]>(`${environment.urls.api}/backoffice/contract-type/${tipoContratoId}/preset_tasks`);
  }

  allPaginated(searchRequest: SearchRequest) {
    return this.http.post<PaginatedResponse<TareaPredefinida>>(
      `${environment.urls.api}/backoffice/preset_tasks`,
      searchRequest
    );
  }

  one(id: number) {
    return this.http.get<TareaPredefinida>(`${environment.urls.api}/backoffice/preset_tasks/${id}`);
  }

  create(data: TareaPredefinida) {
    return this.http.post<TareaPredefinida>(
      `${environment.urls.api}/backoffice/preset_tasks/create`,
      data
    );
  }

  update(id: number, data: TareaPredefinida) {
    return this.http.post<TareaPredefinida>(
      `${environment.urls.api}/backoffice/preset_tasks/${id}/update`,
      data
    );
  }

  delete(id: number) {
    return this.http.delete<TareaPredefinida>(
      `${environment.urls.api}/backoffice/preset_tasks/${id}/delete`
    );
  }

  import(datos: UploadDocumento) {
    const input = new FormData();
    input.append('file', datos.file);
    return this.http.post(`${environment.urls.api}/backoffice/preset_tasks/import`, input);
  }

  export(searchRequest = new SearchRequest()) {
    return this.http.post(`${environment.urls.api}/backoffice/preset_tasks/export`, searchRequest, { responseType: 'blob', observe: 'response' });
  }

  getByTemplate(templateId: number) {
    return this.http.get<TareaPredefinida[]>(`${environment.urls.api}/preset_tasks/${templateId}`);
  }
}
