import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  AzConfirmationModalService,
  AzModalConfig,
  AzModalRef,
  AzModalService,
  AzNotifierService,
  ModalBaseComponent
} from '@azigrene/components';
import {DataListSearchRequest, DataPaginatedSearchRequest} from '@azigrene/searchrequest';
import {TranslocoService} from '@ngneat/transloco';
import {EntregableTarea} from '@shared/models/entregable.model';
import {EnumItem} from '@shared/models/generic.model';
import {DocumentoTarea, Revision, Tarea} from '@shared/models/home.model';
import cronstrue from 'cronstrue/i18n';
import {saveAs} from 'file-saver';
import * as moment from 'moment';
import {take} from 'rxjs/operators';
import {DocumentoService} from '../../services/documento.service';
import {TareaService} from '../../services/tarea.service';
import {AuthService} from '../../../../service/auth.service';
import {environment} from '../../../../../environments/environment';
import {EnumService} from '../../services/enum.service';
import {RevisionDialogComponent} from '../revision-dialog/revision-dialog.component';
import {TareaSubirDocumentoComponent} from '../../pages/tarea/dialogs/tarea-subir-documento/tarea-subir-documento.dialog';
import {EstadoRevisionConfig} from "@shared/components/tag-estado-revision/tag-estado-revision.component";

import * as datefns from "date-fns";
import {ContributionChartDataItem} from "@shared/components/contribution-chart/contribution-chart.component";
import {DateUtils} from "@shared/utils";
import {Periodicidad} from "@shared/enum/enums";

@Component({
  selector: 'app-tarea-detalle',
  templateUrl: './tarea-detalle.component.html',
})
export class TareaDetalleComponent extends ModalBaseComponent implements OnInit {

  tarea: Tarea;
  estadoRevisionConfig = EstadoRevisionConfig;

  revisionesRequest: DataPaginatedSearchRequest<Revision>;
  documentosRequest: DataListSearchRequest<DocumentoTarea>;

  cronDescription: string = null;

  entregables: EntregableTarea[] = [];
  estadosRevision: EnumItem[];
  etiquetas: string[];
  form: FormGroup;
  contributionData: ContributionChartDataItem[] = [];
  years: number[] = DateUtils.generateArrayOfYears(5).reverse();
  activeYear: number = new Date().getUTCFullYear();

  constructor(
    private tareaService: TareaService,
    private documentoService: DocumentoService,
    private formBuilder: FormBuilder,
    private dialogService: AzModalService,
    private translateService: TranslocoService,
    private confirmationService: AzConfirmationModalService,
    private notifier: AzNotifierService,
    public auth: AuthService,
    private config: AzModalConfig,
    private ref: AzModalRef,
    private enumService: EnumService
  ) {
    super(config, ref);
    this.form = this.formBuilder.group({
      id: [null, Validators.required],
      nombre: [null, Validators.required],
      descripcion: [null],
      observaciones: [null],
    });
    if (!config.data || (!config.data.tarea && !config.data.tareaId)) {
      this.ref.close();
    }
    this.tarea = config.data.tarea;
    this.initForm(this.tarea);
    if (config.data.loadTarea) {
      this.tareaService.getOne(this.tarea.contratoId, this.tarea.id).subscribe((tarea) => {
        this.tarea = tarea;
        this.initForm(this.tarea);
      });
    }

    moment.locale(environment.idioma);

    this.documentosRequest = new DataListSearchRequest<DocumentoTarea>(() => this.tareaService.getAllDocuments(this.tarea.contratoId, this.tarea.id, this.documentosRequest.searchRequest));
    this.documentosRequest.searchRequest.addSort('created_at', 'DESC', false);
    this.revisionesRequest = new DataPaginatedSearchRequest<Revision>(() => {
      this.revisionesRequest.searchRequest.addFilter('fecha_teorica_ejecucion_between', [datefns.startOfYear(new Date(this.activeYear, 1,2)), datefns.endOfYear(new Date(this.activeYear, 1,2))], false);
      this.revisionesRequest.searchRequest.addSort('fecha_teorica_ejecucion', 'DESC', false);
      this.revisionesRequest.searchRequest.setPageSize(365);
      return this.tareaService.getRevisionesById(this.tarea.contratoId, this.tarea.id, this.revisionesRequest.searchRequest)
    });


    this.revisionesRequest.onSuccess.subscribe(data => {
      this.contributionData = data.data.map(d => ({
        date: d.fechaTeoricaEjecucion,
        backgroundColor: this.estadoRevisionConfig[d.estadoId].backgroundColor,
        borderColor: this.estadoRevisionConfig[d.estadoId].borderColor,
        item: d
      }))
    })
  }

  ngOnInit(): void {
    this.documentosRequest.load();
    this.revisionesRequest.load();

    this.enumService.estadosRevision().subscribe((estados) => {
      this.estadosRevision = estados;
    });

    if (this.tarea.expresionCron && this.tarea.expresionCron.split(' ').length == 7) {
      this.cronDescription = cronstrue.toString(this.tarea.expresionCron, {
        locale: this.translateService.getActiveLang(),
        use24HourTimeFormat: true,
      });
    }
  }

  initForm(tarea: Tarea): void {
    this.form.patchValue({
      id: tarea.id,
      nombre: tarea.nombre,
      descripcion: tarea.descripcion,
      observaciones: tarea.observaciones,
    });
  }

  delete(): void {
    this.confirmationService.open({
      showCancel: true,
      confirmationText: this.translateService.translate('home.tarea.dialogs.delete.texto'),
      okText: this.translateService.translate('actions.accept'),
      cancelText: this.translateService.translate('actions.cancel'),
      header: this.translateService.translate('home.tarea.dialogs.delete.titulo'),
      accept: () => {
        this.tareaService.delete(this.tarea.contratoId, this.tarea.id).subscribe(
          () => {
            this.notifier.add({
              detail: this.translateService.translate('home.tarea.alertas.delete.success'),
              severity: 'success',
            });
            this.ref.close();
          },
          () => {
            this.notifier.add({
              detail: this.translateService.translate('home.tarea.alertas.delete.error'),
              severity: 'error',
            });
          }
        );
      },
    });
  }

  openDialogoDocumento(): void {
    const ref = this.dialogService.open(TareaSubirDocumentoComponent, {
      styleClass: 'w-full md:w-1/3',
      data: {
        contratoId: this.tarea.contratoId,
        tareaId: this.tarea.id,
      },
    });

    ref.onClose.pipe(take(1)).subscribe(() => {
      this.documentosRequest.load();
    });
  }

  openDialogRevision(revision: Revision): void {
    this.dialogService.open(RevisionDialogComponent, {
      showHeader: true,
      styleClass: 'w-full md:max-w-4xl bg-gray-50',
      data: {
        revisionId: revision.id,
        contratoId: this.tarea.contratoId,
        tareaId: this.tarea.id,
      },
    });
  }

  update(): void {
    this.tareaService.update(this.tarea.id, this.tarea.contratoId, {...this.tarea, ...this.form.value}).subscribe(
      (updated) => {
        this.initForm(updated);
      },
      () => this.notifier.showInfoError('misc.notifier.update-error')
    );
  }

  downloadDoc(documento: DocumentoTarea): void {
    this.documentoService.downloadDocument(documento.documento.id).subscribe((data) => {
      saveAs(data, documento.documento.filename);
    });
  }

  deleteDoc(documento: DocumentoTarea): void {
    this.confirmationService.open({
      showCancel: true,
      cancelText: this.translateService.translate('actions.cancel'),
      confirmationText: this.translateService.translate('frontoffice.contrato.tareas.detalle.document-confirm-delete', {
        value: documento.documento.nombre,
      }),
      accept: () => {
        this.documentoService.deleteDocument(documento.documento.id).subscribe(() => {
          this.notifier.showInfoError('Documento eliminado');
          this.documentosRequest.load();
        });
      },
    });
  }

  reload(): void {
    this.tareaService
      .getOne(this.tarea.contratoId, this.tarea.id)
      .pipe(take(1))
      .subscribe((tarea) => {
        this.tarea = tarea;
        this.initForm(tarea);
      });
  }

  changePeriodicidad(event: { cron: string; periodicidad: Periodicidad }): void {
    this.tarea.periodicidad = event.periodicidad;
    this.tarea.expresionCron = event.cron;
    this.reload();
  }

  updateEstadoDefecto(estadoId: number): void {
    this.tarea.estadoDefectoId = estadoId;
    this.tareaService.update(this.tarea.id, this.tarea.contratoId, this.tarea).subscribe(() => this.reload());
  }
}
