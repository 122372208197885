import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { PaginatedResponse, SearchRequest } from '@azigrene/searchrequest';
import { TipoContrato } from '@shared/models/home.model';

@Injectable()
export class BackofficeTipoContratoService {
  constructor(private http: HttpClient) {}

  all() {
    return this.http.get<TipoContrato[]>(`${environment.urls.api}/backoffice/contract_types`);
  }
  allPaginated(searchRequest: SearchRequest) {
    return this.http.post<PaginatedResponse<TipoContrato>>(
      `${environment.urls.api}/backoffice/contract_types`,
      searchRequest
    );
  }
  one(id: number) {
    return this.http.get<TipoContrato>(`${environment.urls.api}/backoffice/contract_types/${id}`);
  }

  create(data: TipoContrato) {
    return this.http.post<TipoContrato>(
      `${environment.urls.api}/backoffice/contract_types/create`,
      data
    );
  }

  update(id: number, data: TipoContrato) {
    return this.http.post<TipoContrato>(
      `${environment.urls.api}/backoffice/contract_types/${id}/update`,
      data
    );
  }
  delete(id: number) {
    return this.http.delete<TipoContrato>(
      `${environment.urls.api}/backoffice/contract_types/${id}/delete`
    );
  }
}
