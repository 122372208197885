<div class="modal-content">
  <form [formGroup]="form">
    <div class="flex flex-wrap w-full mt-4">
      <div class="az-form-group w-full md:w-full">
        <label>{{ 'home.tarea.documento.entregable' | transloco }}</label>
        <az-select [disabled]="booleanAuxiliar" [options]="tipoEntregable" [hasSearch]="true" [dropdownFullSize]="true" formControlName="tipoEntregableId"></az-select>
      </div>
    </div>
    <div class="az-form-group">
      <label> {{ 'home.tarea.documento.fichero' | transloco }}</label>
      <input class="az-input truncate" (change)="onFileChange($event)" type="file" />
    </div>
    <div class="az-form-group">
      <label> {{ 'home.tarea.documento.nombre' | transloco }}</label>
      <az-input-text formControlName="nombre" type="text"></az-input-text>
    </div>
    <!-- <div class="az-form-group">
      <label> {{ 'home.tarea.documento.observaciones' | transloco }}</label>
      <textarea class="data" formControlName="observaciones"></textarea>
    </div> -->
  </form>
</div>
<div class="modal-footer">
  <button azButton level="primary" [disabled]="!form.valid"
          (click)="submit()">{{ 'actions.create' | transloco }}</button>
</div>
